import React from 'react'
import { featured } from "../Special/Data"
import './Special.css'


const Special = () => {
  return (
    <>
    <div className="offer">
        <h1>Our Special Offers</h1>
        <div className="special">
        {featured.map((items, index) => (
          <div className='box' key={index}>
            <img src={items.cover} alt='' />
            <h4>{items.name}</h4>
            <p>{items.adresse}</p>
            <label>{items.Prix} <span>{items.past}</span></label>
          </div>
        ))}
        </div>
        <button>View More</button>
    </div>
    </>
  )
}

export default Special