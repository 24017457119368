import React from 'react'
import Header from './Header/Header'
import Home from './Home/Home'
import {
    BrowserRouter as Router,
    Routes, Route
  } from "react-router-dom";
const Pages = () => {
  return (
    <>
    
    <Router>
        <Header />
        <Routes>
            <Route path='/' element={<Home />} />
        </Routes>
        </Router >
           </>
  )
}

export default Pages