import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Pages from './Component/Pages';
function App() {
  return (
    <div className="App">
      <Pages />
    </div>
  );
}

export default App;
