import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
import mg from '../../Assest/icons8-utilisateur-64.png'
import logo from '../../Assest/logo.png'
const Header = () => {
  return (
    <>
    <div className="header">
        <div className="logo">
            <img src={logo} />
        </div>
        <div className="bar">
            <ul>
            <li ><Link to="/" className='active'>Home</Link></li>
                <li>About Us</li>
                <li>Listings</li>
                <li>Agents</li>
                <li>Contacts</li>
           
            </ul>
        </div>
        <div className="cnx">
        <img src={mg} />
        <button className='Btn'>Add Listing</button>
        </div>
    </div>
    </>
  )
}

export default Header