import React, { useState }  from 'react'
import './Home.css'
import styled from 'styled-components';
import 'remixicon/fonts/remixicon.css'
import AboutCard from '../About/AboutCard';
import Header from '../Header/Header';
import Special from '../Special/Special';

const Main = styled("div")`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;

const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
padding: 0.4em 2em 0.4em 1em;
box-shadow: 0 2px 3pxrgba(0,0,0,0.15);
font-weight: 500;
font-size: 1.2rem;
color: gray;
background: #ffffff;
border-radius: 9px;
text-align: left;
text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
padding: 0;
margin: 0;
padding-left: 1em;
background: #ffffff;
border: 2px solid #e5e5e5;
box-sizing: border-box;
color: gray;
font-size: 1.2rem;
font-weight: 500;
text-align: left;
border-radius: 9px;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  return (
   <>
   <div className="home">
<h1>Let Us Guide You Home</h1>
<p>We help You Get To Your Dream Property</p>
<div className="search">
<DropDownContainer>
<DropDownHeader onClick={toggling}>Mangoes <i class="ri-arrow-down-s-line"></i></DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem>Mangoes</ListItem>
              <ListItem>Apples</ListItem>
              <ListItem>Oranges</ListItem>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      <DropDownContainer>
<DropDownHeader onClick={toggling}>Mangoes <i class="ri-arrow-down-s-line"></i></DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem>Mangoes</ListItem>
              <ListItem>Apples</ListItem>
              <ListItem>Oranges</ListItem>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      <DropDownContainer>
<DropDownHeader onClick={toggling}>Mangoes <i class="ri-arrow-down-s-line"></i></DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem>Mangoes</ListItem>
              <ListItem>Apples</ListItem>
              <ListItem>Oranges</ListItem>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      <DropDownContainer>
<DropDownHeader onClick={toggling}>Mangoes <i class="ri-arrow-down-s-line"></i></DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem>Mangoes</ListItem>
              <ListItem>Apples</ListItem>
              <ListItem>Oranges</ListItem>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    <button className='btnSearhc'>Search</button>
  
</div>
   </div>
   
   <div className="number">
    <div>
      <span>4</span>
      <span>Professional Agents</span>
    </div>
    <div>
      <span>7</span>
      <span>Years Of Experience</span>
    </div>
    <div>
      <span>300+</span>
      <span>Deals Closed</span>
    </div>
   </div>
   <AboutCard />
   <Special />
   </>
  )
}

export default Home