import h1 from './../../Assest/1.jpg';
import h2 from './../../Assest/2.jpg';
import h3 from './../../Assest/3.jpg';

export const featured = [
    {
      cover: h1,
      name: "Family House in Glasgon",
      adresse:"60 High St, Glasgon,London",
      Prix: "$ 470,000",
      past: "$ 500,000",
    },
    {
      cover: h2,
      name: "Apartment in New York",
      adresse:"1685 Carroll St,Brooklyn",
      Prix: "$ 295,000",
      past: "$ 350,000",
    },
    {
      cover: h3,
      name: "Villa in Miami",
      adresse:"1685 Carroll St,Miami",
      Prix: "$ 7500/month",
      past: "$ 9700/month",
    },
  ]