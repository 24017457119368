import React from 'react'
import './About.css'
import img1 from './../../Assest/people-gdc87c1411_1280.jpg'
import img2 from './../../Assest/student-ge7ca1172e_1280.jpg'

const AboutCard = () => {
  return (
    <>
    <div className="about">
    <div className="fistline">
        <div className='txt'>
        <h1>About Us</h1>
            <p>One Agency is a revolution in real estate, empowering both individual real estate professionals and established agencies to take control of their own business and their future and bring an entirely new level of service to cliets.</p>
            <button>Learn more</button>
        </div>
        <div className="i1">
            <img src={img1} alt="" />
        </div>
    </div>

    <div className="secondline">
    <div className="i2">
            <img src={img2} alt="" />
        </div>
        <div className='txt2'>
        <h1>Our Success</h1>
            <p>One Agency is a revolution in real estate, empowering both individual real estate professionals and established agencies to take control of their own business and their future and bring an entirely new level of service to cliets.</p>
            <button>Learn more</button>
        </div>

    </div>
    </div>
    </>
  )
}

export default AboutCard